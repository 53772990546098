header, header .container {
   background-color:#243d7f;
   color:#fff;
   font-size: 20px;
}
.btn-primary {
   background-color: #243d7f !important;
   border-color: #243d7f !important;
}
.container, .container-fluid {
   background-color: white;
}
html, body {
   height: 100%;
   margin: 0;
}
.app, #root {
   background-color: #f0f0f0;
   min-height: 100vh;
   overflow-y: hidden;
}
.roundsList .btn {
   width:100%
}
.roundsActions .btn {
   margin-right:5px;
}