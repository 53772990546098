.nav-item:not(.ms-auto) a {
	-moz-box-shadow:inset 0px 1px 0px 0px #f9eca0;
	-webkit-box-shadow:inset 0px 1px 0px 0px #f9eca0;
	box-shadow:inset 0px 1px 0px 0px #f9eca0;
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #f0c911), color-stop(1, #f2ab1e));
	background:-moz-linear-gradient(top, #f0c911 5%, #f2ab1e 100%);
	background:-webkit-linear-gradient(top, #f0c911 5%, #f2ab1e 100%);
	background:-o-linear-gradient(top, #f0c911 5%, #f2ab1e 100%);
	background:-ms-linear-gradient(top, #f0c911 5%, #f2ab1e 100%);
	background:linear-gradient(to bottom, #f0c911 5%, #f2ab1e 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0c911', endColorstr='#f2ab1e',GradientType=0);
	background-color:#f0c911;
	border:1px solid #e65f44;
	display:inline-block;
	cursor:pointer;
	color:#c92200;
	font-size:15px;
	font-weight:bold;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ded17c;
}
.nav-item:not(.ms-auto) a:hover {
   background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #f2ab1e), color-stop(1, #f0c911));
	background:-moz-linear-gradient(top, #f2ab1e 5%, #f0c911 100%);
	background:-webkit-linear-gradient(top, #f2ab1e 5%, #f0c911 100%);
	background:-o-linear-gradient(top, #f2ab1e 5%, #f0c911 100%);
	background:-ms-linear-gradient(top, #f2ab1e 5%, #f0c911 100%);
	background:linear-gradient(to bottom, #f2ab1e 5%, #f0c911 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#f2ab1e', endColorstr='#f0c911',GradientType=0);
	background-color:#f2ab1e;
}


        