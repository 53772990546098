.reset {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}

.reset__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}

.reset__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}

.reset div {
  margin-top: 7px;
}

a {
  color:  black !important;
}

.fc .fc-col-header-cell-cushion { /* needs to be same precedence */
  padding-top: 5px; /* an override! */
  padding-bottom: 5px; /* an override! */
}

.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
}

.table>:not(:first-child) {
  border-top:  inherit !important;
}